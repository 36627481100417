import React, { Fragment, useState, useEffect } from 'react'
import { Button, Form, FormGroup, UncontrolledAlert, Input, Badge, Col, Row, Card, CardBody } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import MaterialTable from 'material-table';
import { CSVLink } from "react-csv";
import { getData, getFecha, updateItem } from '../../vibe/helpers/helpers'
import { getCookie } from '../../vibe/helpers/AuthenticatedComponent'

const ReporteCitas = ()=>{
  const [solicitudes, setSolicitudes] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [departamentoid, setDepartamentoID] = useState(0);
  const [solicitudesfiltradas, setSolicitudesFiltradas] = useState([]);
  const [fecha, setFecha] = useState('');
  const [mensaje, actualizaMensaje] = useState({
    status:false,
    message:'',
    label:''
  });

  const getSolicitudes = async ()=>{
    let fecha_actual = await getFecha();
    setFecha(fecha_actual);
    const usr = JSON.parse(getCookie('USER_GOLSAGE'));
    const send = await getData('agenda');
    console.log(send);
    let agenda_depts = [];
    if(send.data){
      if(usr.dept !== null){
        agenda_depts = send.data.filter(item=> parseInt(item.departamento_id) === parseInt(usr.dept));
        setSolicitudes(agenda_depts);
        setSolicitudesFiltradas(agenda_depts);
      }else{
        setSolicitudes(send.data);
        setSolicitudesFiltradas(send.data);
      }
      return;
    }
  }

  const getDepartamento = async()=>{
    const send = await getData('departamento');
    console.log(send);
    if(send.data){
      setDepartamentos(send.data)
      return;
    }
  }

  useEffect(()=>{
    getSolicitudes();
    getDepartamento();
  },[]);


  const actualizaState = e =>{
    setFecha(e.target.value)
    console.log(e.target.value);
    const filtro_solicitudes = solicitudes.filter(item=>item.fecha_agenda === e.target.value);
    setSolicitudesFiltradas(filtro_solicitudes);
    //setDepartamentoID(0);
  }

  const actualizaStateDept = e =>{
    const filtro_solicitudes = solicitudes.filter(item=>parseInt(item.departamento_id) === parseInt(e.target.value) && item.fecha_agenda === fecha );
    setSolicitudesFiltradas(filtro_solicitudes);
    setDepartamentoID(e.target.value);
  }

  

  const cancelarCita = async id =>{
    actualizaMensaje({
      status:true,
      label: "primary",
      message: "Cancelando...."
    })
    let datos = {
      'estatus_id': 6,
    }
    const ruta = 'solicitud';
    const send = await updateItem(ruta+'/'+id,datos);
    if(send.code === 200){
      actualizaMensaje({
        status:true,
        label: "success",
        message: "Se ha cancelado correctamente"
      })
      getSolicitudes();
      return;
    }

    actualizaMensaje({
      status:true,
      label: "danger",
      message: "Algo ha ocurrido y no se guardaron los datos"
    })
  }

  const cambiarCalendario = async ( id, calendario) =>{
    actualizaMensaje({
      status:true,
      label: "primary",
      message: "Cambiando...."
    })
    let datos = {
      'num_calendario': calendario,
    }
    const ruta = 'agenda';
    const send = await updateItem(ruta+'/'+id,datos);
    console.log(send);
    if(send.code === 200){
      actualizaMensaje({
        status:true,
        label: "success",
        message: "Se ha cambiado correctamente"
      })
      getSolicitudes();
      return;
    }
  }

  const [columnastablas, setColumnasTablas] = useState(
    [
      // { title: "Tipo", field: "desc_tipo_tramite" },
      { 
        title: "Folio",
        field: "folio", 
        render: (row)=><NavLink to={`/detalle/?id=${row.id}`}>{ row.folio }</NavLink>
      },
      { title: "Trámite", field: "desc_tramite" },
      { title: "Nombre", field: "nombre_completo" },
      { title: "Matricula", field: "matricula" },
      { title: "Teléfono", field: "descripcion_solicitud" },
      { title: "Departamento", field: "desc_departamento" },
      { title: "Fecha Cita", field: "fecha_agenda" },
      { title: "Hora Cita", field: "hora_agenda" },
      { 
        title: "Calendario", 
        field: "desc_estatus",
        render: (row)=> 
          <p>
            { 
              parseInt(row.num_calendario) === 3 ? 
                'Ex' : 
                  parseInt(row.num_calendario) === 1 ?
                  <Fragment>
                    <Button onClick={()=>cambiarCalendario(row.agenda_id, 1)} mt="10" color="success" type="buttom">1</Button>
                    <Button onClick={()=>cambiarCalendario(row.agenda_id, 2)} mt="10" color="default" type="buttom">2</Button>
                  </Fragment>
                  : 
                  <Fragment>
                    <Button onClick={()=>cambiarCalendario(row.agenda_id, 1)} mt="10" color="default" type="buttom">1</Button>
                    <Button onClick={()=>cambiarCalendario(row.agenda_id, 2)} mt="10" color="success" type="buttom">2</Button>
                  </Fragment>
            } 
          </p>
      },
      { 
        title: "Estatus", 
        field: "desc_estatus",
        render: (row)=><Badge color={ row.desc_estatus === 'Agendado' ? 'info' :  row.desc_estatus === 'Atendido' ? 'primary' : row.desc_estatus === 'Para entrega' ? 'success' :  row.desc_estatus === 'Agendado para entrega' ? 'info' : 'warning' } >{ row.desc_estatus }</Badge>
      },
      { 
        title: "", 
        render: (row)=>
          parseInt(row.num_calendario) === 1 ?
            <Button onClick = { ()=>{window.location = '/calendario/?calendario=1&fol='+row.folio+'&actualizar=true&id='+row.agenda_id;} }  style={{ marginLeft: 20 }} color="success" type="button"  >Reagendar</Button>
          :
            <Button onClick = { ()=>{window.location = '/calendario2/?calendario=2&fol='+row.folio+'&actualizar=true&id='+row.agenda_id;} }  style={{ marginLeft: 20 }} color="success" type="button"  >Reagendar</Button>
      },
      { 
        title: "", 
        render: (row)=><Button onClick={()=>cancelarCita(row.id)} mt="10" color="danger" type="buttom">Cancelar</Button>
      },
    ]
  );

  const headers = [
    { label: "Folio", key: "folio" },
    { label: "Trámite", key: "desc_tramite" },
    { label: "Nombre", key: "nombre_completo" },
    { label: "Matricula", key: "matricula" },
    { label: "Telefono", key: "descripcion_solicitud" },
    { label: "Departamento", key: "desc_departamento" },
    { label: "Fecha Cita", key: "fecha_agenda" },
    { label: "Hora Cita", key: "hora_agenda" },
    // { label: "Estatus", key: "desc_estatus" }
  ];


  return(
  <Row>
    <Col md={{ size: 12 }}>
      <Card>
      <CardBody>
      <Row>
        <Col md={{ size: 8 }}>
          <Row>
            <Col md={{ size: 6 }}>
              <FormGroup>
                <Input 
                  type="date" 
                  name="fecha" 
                  id="fecha" 
                  onChange = {actualizaState}
                  value = { fecha }
                  />
              </FormGroup>
            </Col>
            {/* <Col md={{ size: 6 }}>
              <FormGroup>
                <Input 
                  type="select" 
                  name="departamento_id" 
                  id="departamento_id"
                  value = { departamentoid }
                  onChange = {actualizaStateDept}
                  >
                  <option value="0" >Seleccion un departamento </option>
                  {
                    departamentos ? 
                      departamentos.map(item=>(
                        <option value={item.id} >{ item.desc_departamento }</option>
                      ))
                    : null
                  }
                </Input>
              </FormGroup>
            </Col> */}
          </Row>
        </Col>
        <Col md={{ size: 4 }}>
          <Button mt="10" color="success" type="buttom"><CSVLink data={solicitudesfiltradas} headers={headers}  filename={`Citas-${fecha}.csv`} style={{color: 'white', width: '10%'}}>Descargar Listado</CSVLink> </Button>
        </Col>
          {
            mensaje.status ?
              <UncontrolledAlert color={mensaje.label}>
                {mensaje.message}
              </UncontrolledAlert>
            : null
          }
      </Row>
      <MaterialTable
        style={{ width: '85%' }}
        options={{
          headerStyle:{fontSize:'12px'},
          bodyStyle:{fontSize:'12px'},
          pageSize: 20
        }}
        title="Citas"
        columns={columnastablas}
        data={solicitudesfiltradas}
        onRowSelected
      />
      </CardBody>
      </Card>
    </Col>
  </Row>
  )
}

export default ReporteCitas