import { getCookie }  from './AuthenticatedComponent'
// const baseRuta = 'http://127.0.0.1:8000/api/'
const baseRuta = 'https://app.itsc.edu.mx/api/'
//const baseRuta = 'https://golsticketagenda-api.golsystems.com/api/'
const token = getCookie('STORAGE_KEY_GOLSAGE');


export async function insertData(ruta,objeto){
  try{
    const requestOptions = {
      method: 'POST',
      headers: { 
        'Authorization': "Bearer " + token,
        //'Content-Type': 'multipart/form-data' 
      },
      body: objeto,

    };
    let response = await fetch(baseRuta+ruta, requestOptions);
    let data = await response.json();
    return data;
  }catch(err){
    return {status:'error', message:'Ha ocurrido algo:' +err};
  }
}

export async function insertDataJson(ruta,objeto){
  try{
    const requestOptions = {
      method: 'POST',
      headers: { 
        'Authorization': "Bearer " + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(objeto),

    };
    let response = await fetch(baseRuta+ruta, requestOptions);
    let data = await response.json();
    return data;
  }catch(err){
    return {status:'error', message:'Ha ocurrido algo:' +err};
  }
}

export async function getData(ruta){
  try{
    const requestOptions = {
      method: 'GET',
       headers: { 
        'Authorization': "Bearer " + token,
        'Content-Type': 'application/json' 
       }
    };

    let response = await fetch(baseRuta+ruta,requestOptions);
    let data = await response.json();
    return data;

  }catch(err){
    return {status:'error', message:'Ha ocurrido algo:' +err};
  }
}

export async function updateItem(ruta,objeto){
  try{
    const requestOptions = {
      method: 'PUT',
      headers: { 
        'Authorization': "Bearer " + token,
        'Content-Type': 'application/json' 
      },
      body: JSON.stringify(objeto),
    };

    let response = await fetch(baseRuta+ruta,requestOptions);
    let data = await response.json();
    return data;

  }catch(err){
    return {status:'danger', message:'Ha ocurrido algo:' +err};
  }
}

export async function deleteItem(ruta){
  try{
    const requestOptions = {
      method: 'DELETE',
      headers: { 
        'Authorization': "Bearer " + token,
        'Content-Type': 'application/json' 
      }
    };

    let response = await fetch(baseRuta+ruta,requestOptions);
    let data = await response.json();
    return data;

  }catch(err){
    return {status:'danger', message:'Ha ocurrido algo:' +err};
  }
}

export async function login(ruta,objeto){
  try{
    const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json' 
      },
      body: JSON.stringify(objeto),

    };
    let response = await fetch(baseRuta+ruta, requestOptions);
    let data = await response.json();
    return data;
  }catch(err){
    return {status:'error', message:'Ha ocurrido algo:' +err};
  }
}

export async function insertActivity(usuario, seccion, actividad, color){
  try{
    let formData = new FormData();
    formData.append('desc_1', seccion);
    formData.append('desc_2', color);
    formData.append('usuario', usuario);
    formData.append('seccion', actividad);

    const requestOptions = {
      method: 'POST',
      headers: { 
        'Authorization': "Bearer " + token,
        //'Content-Type': 'multipart/form-data' 
      },
      body: formData,

    };
    let response = await fetch(baseRuta+'actividad', requestOptions);
    let data = await response.json();
    return data;
  }catch(err){
    return {status:'error', message:'Ha ocurrido algo:' +err};
  }
}

export async function  calcularTotal(venta, setVenta, detalleventa){
  const sumaPrecios = await detalleventa.reduce((prev, next) => prev + parseFloat(next.importe_partida), 0);
  console.log(sumaPrecios)
  if(sumaPrecios > 0){
    await setVenta({
      ...venta,
      subtotal: sumaPrecios,
      iva: parseFloat(sumaPrecios * (0.16)),
      total: parseFloat(sumaPrecios * (1.16))
    })
  }
}


export async function buscarTablasConsultas(lista,item,field,field2,field3){
  const resultado = lista.filter(resul=>{

    if(resul[field]) {} else{ resul[field] = '';}
    if(resul[field2]) {} else{ resul[field2] = '';}
    if(resul[field3]) {} else{ resul[field3] = '';}

    if(resul[field].toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"").includes(item.toLowerCase()) || 
      resul[field2].toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"").includes(item.toLowerCase()) ||
      resul[field3].toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"").includes(item.toLowerCase())
       ){
      return resul;
    }
  })
  return resultado;
}

export function sumarMinutos(fecha, minutos){
    // creacion de un metodo  addMins para la clase Date
    Date.prototype.addMins = function(m) {     
      this.setTime(this.getTime() + (m*60*1000));  // minutos * seg * milisegundos
      return this;    
    } 

    var fechaI2 = new Date (fecha);
    let minutoSumar = minutos;

    fechaI2.addMins(minutoSumar);
  return fechaI2;
}

export function validaFechaExistente(fecha_actual, fecha1, fecha2){
  let f_actual = new Date(fecha_actual);
  let f_1 = Date.parse(fecha1);
  let f_2 = Date.parse(fecha2); 
  let existe = false;
  if(f_actual >= f_1 && f_actual <= f_2){
    existe = true;
  }else{
    existe = false;
  }

  return existe
}

export function getFecha(fecha1){
  var fecha = new Date(); 
  var mes = fecha.getMonth()+1; 
  var dia = fecha.getDate(); 
  var ano = fecha.getFullYear();
  if(dia<10)
    dia='0'+dia; 
  if(mes<10)
    mes='0'+mes 
  fecha = ano+"-"+mes+"-"+dia;
  return fecha;
}

export function formatearFecha(fecha, tipo){
  let fecha0 = fecha;
  let fecha1;
  let fecha2;
  if(tipo ==='fecha'){
    var mes = fecha0.getMonth()+1; 
    var dia = fecha0.getDate(); 
    var ano = fecha0.getFullYear();
    if(dia<10)
      dia='0'+dia; 
    if(mes<10)
      mes='0'+mes 
    fecha1 = ano+"-"+mes+"-"+dia;
    return fecha1;
  }else if(tipo ==='hora'){
    fecha2 = fecha0.getTime();
    return fecha2;
  }
}

export async function mensajeAlerta (titulo, mensaje, funcion){

  alert(titulo, mensaje,[
    {
      text: "Cancel",
      onPress: () => console.log("Cancel Pressed"),
      style: "cancel"
    },
    { text: "OK", onPress: () => funcion() }
  ]);
}